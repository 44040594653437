import { FaBars } from "react-icons/fa";
import "./navigation.css";
import { useState } from "react";

const Navigation = () => {
  const [menuActive, setMenuActive] = useState(false);

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setMenuActive(false); // Close menu on navigation
    }
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <section className="hero overlay">
      <header className="header">
        <div className="container">
          <div className="header-row">
            {/* Logo Section */}
            <div className="logo-container">
              <img
                src="https://res.cloudinary.com/astcdubai/image/upload/v1736164746/45th_Ica_white_logo_xoggsq.png"
                alt="logo"
                className="logo-img"
              />
            </div>
            {/* Mobile Toggle Button */}
            <div className="toggle-mobile-but" onClick={toggleMenu}>
              <FaBars size={30} />
            </div>
            {/* Menu List */}
            <nav className="main-nav">
              <ul className={menuActive ? "active" : ""}>
                {[
                  { id: "hero", label: "Home" },
                  { id: "about", label: "About" },
                  { id: "speakers", label: "Speakers" },
                  { id: "sponsors", label: "Sponsors / Exhibitors" },
                  { id: "agenda", label: "Agenda" },
                  { id: "tickets", label: "Tickets" },
                  { id: "partners", label: "Partners" },
                  { id: "committee", label: "Committee" },
                  { id: "information", label: "Information" },
                ].map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleScroll(item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Navigation;
