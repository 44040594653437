import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { db, collection } from "../../firebaseConfig";
import { getDocs, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

type Registration = {
  key: string;
  name: string;
  email: string;
  organization: string;
  totalAmount: string;
  totalDelegateTickets: string;
  totalUaefmaTickets: string;
  coupon: string;
  timestamp: any; // Add timestamp field to the type
};

const ManageRegistrations: React.FC = () => {
  const navigate = useNavigate();
  const [registrations, setRegistrations] = useState<Registration[]>([]);

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    // Start the interval to fetch registrations every 30 seconds
    const intervalId = setInterval(() => {
      fetchRegistrations(); // Fetch the data silently
    }, 30000); // 30 seconds in milliseconds

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once when the component is mounted

  const fetchRegistrations = async () => {
    const registrationsRef = collection(db, "orders");

    // Query to order by timestamp in descending order (most recent first)
    const q = query(registrationsRef, orderBy("timestamp", "desc"));

    const querySnapshot = await getDocs(q); // Execute the query with ordering
    const registrationsData: Registration[] = querySnapshot.docs.map((doc) => ({
      key: doc.id,
      name: doc.data().name,
      email: doc.data().email,
      organization: doc.data().organization,
      totalAmount: doc.data().totalAmount,
      totalDelegateTickets: doc.data().totalDelegateTickets,
      totalUaefmaTickets: doc.data().totalUaefmaTickets,
      coupon: doc.data().coupon,
      timestamp: doc.data().timestamp.toDate(), // Convert timestamp to JavaScript Date
    }));

    setRegistrations(registrationsData);
  };

  useEffect(() => {
    fetchRegistrations();
  }, []);

  const orderColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Organization", dataIndex: "organization", key: "organization" },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "Delegate Tickets",
      dataIndex: "totalDelegateTickets",
      key: "totalDelegateTickets",
    },
    {
      title: "UAEFMA Member Tickets",
      dataIndex: "totalUaefmaTickets",
      key: "totalUaefmaTickets",
    },
    { title: "Coupon", dataIndex: "coupon", key: "coupon" },
    {
      title: "Date of Registration",
      dataIndex: "timestamp", // Data index points to the timestamp field
      key: "timestamp",
      render: (timestamp: any) => {
        // Format the date to a readable format (e.g., "YYYY-MM-DD HH:mm:ss")
        const date = new Date(timestamp);
        return date.toLocaleString(); // Display a localized date string
      },
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Registrations
      </h1>
      <br />
      <Table
        columns={orderColumns}
        dataSource={registrations}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageRegistrations;
