import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { collection, getDocs, orderBy, query } from "firebase/firestore"; // Firestore functions
import "./speaker.css";
import SpeakerModal from "./SpeakerModal";
import { db } from "../firebaseConfig";

const SpeakersSection = () => {
  const [showAll, setShowAll] = useState(false);
  const [speakers, setSpeakers] = useState<any[]>([]); // State for speakers data

  const placeholderImage =
    "https://res.cloudinary.com/astcdubai/image/upload/v1736906559/placeholder_male.jpg";

  useEffect(() => {
    const fetchSpeakers = async () => {
      const registrationsRef = collection(db, "speakers");
      try {
        const q = query(registrationsRef, orderBy("timestamp", "asc"));
        const querySnapshot = await getDocs(q);

        const speakersData: any[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSpeakers(speakersData);
      } catch (error) {
        console.error("Error fetching speakers data:", error);
      }
    };

    fetchSpeakers();
  }, []);

  const visibleSpeakers = showAll ? speakers : speakers.slice(0, 4);

  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (speaker: any) => {
    setSelectedSpeaker(speaker);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedSpeaker(null);
    setIsModalOpen(false);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="speakers" className="speakers pt-120 pb-80 brd-bottom">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
            <h1 className="title">Speakers</h1>
            <p className="sub-title-3 mt-10">
              Hear from world-renowned experts and industry leaders
              shaping the future of financial markets
            </p>
          </div>
        </div>

        {!isSmallScreen ? (
          <div className="row">
            <TransitionGroup className="row">
              {visibleSpeakers.map((speaker) => (
                <CSSTransition key={speaker.id} timeout={500} classNames="fade">
                  <div className="col-sm-3 pd-1">
                    <div className="block-speaker">
                      <div className="block-img soft">
                        <div className="speaker-img">
                          <img
                            src={speaker.imageUrl || placeholderImage}
                            alt={speaker.name}
                          />
                          <div className="bio-container">
                            <p className="bio">{speaker.shortBio}</p>
                          </div>
                        </div>
                        <div className="block-info-2">
                          <p>
                            <strong>{speaker.name}</strong>
                            <span>{speaker.role}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        ) : (
          <div className="speakers-grid">
            {visibleSpeakers.map((speaker) => (
              <CSSTransition key={speaker.id} timeout={500} classNames="fade">
                <div className="speaker-card">
                  <div className="block-speaker">
                    <div className="block-img soft">
                      <div className="speaker-img">
                        <img
                          src={speaker.imageUrl || placeholderImage}
                          alt={speaker.name}
                        />
                        <div className="bio-container">
                          <p className="bio">{speaker.shortBio}</p>
                        </div>
                      </div>
                      <div className="block-info-2">
                        <p>
                          <strong>{speaker.name}</strong>
                          <span>{speaker.role}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ))}
          </div>
        )}
      </div>

      {speakers.length > 4 && (
        <div className="row text-center mt-20">
          <h3 className="sub-title-2">{!showAll ? "View all speakers" : ""}</h3>

          <div
            className={`btn-primary ${showAll ? "view-less" : ""}`}
            onClick={() => setShowAll(!showAll)}
          >
            <span className={`arrow ${showAll ? "up" : "down"}`}></span>
          </div>
          <h3 className="sub-title-2">{!showAll ? "" : "View less"}</h3>
        </div>
      )}

      {/* Speaker Modal */}
      <SpeakerModal
        isOpen={isModalOpen}
        speaker={selectedSpeaker}
        onClose={closeModal}
      />
    </section>
  );
};

export default SpeakersSection;
