import React, {  } from "react";
import "./speakerModal.css";

interface SpeakerModalProps {
  isOpen: boolean;
  speaker: { name: string; fullBio: string; imageUrl: string; role: string } | null;
  onClose: () => void;
}

const placeholderImage =
"https://res.cloudinary.com/astcdubai/image/upload/v1736906559/placeholder_male.jpg";

const SpeakerModal: React.FC<SpeakerModalProps> = ({
  isOpen,
  onClose,
  speaker,
}) => {
  if (!isOpen) return null;
  console.log(`File: SpeakerModal.tsx, Line: 19 -> speaker`, speaker);
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h1 className="title">{speaker?.name}</h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="modal-body">
          <div className="speaker-items-column">
            <img
              src={speaker?.imageUrl || placeholderImage}
              alt={speaker?.name}
              className="modal-speaker-image"
            />
          </div>

          <div className="speaker-info-column">
            <h3 className="title">{speaker?.role}</h3>
            <br />
            <p className="pb-10">{speaker?.fullBio}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerModal;
