import React, { useContext, useState } from "react";
import { CartContext } from "./context/CartContext";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import "./tickets.css";
import OrdersModal from "./Orders";
import { Alert, Snackbar } from "@mui/material";

const TicketsSection = () => {
  const { addToCart, updateQuantity } = useContext(CartContext);
  const [activeTicketId, setActiveTicketId] = useState<number | null>(1);
  const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false); // Modal state

  // Snackbar state
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }>({
    open: false,
    message: "",
    severity: "success", // success | error | info | warning
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const tickets = [
    {
      id: 1,
      name: "Delegate",
      price: 1950,
      description: [
        "Full access to all the panel discussions and key note speeches",
        "Full access to all coffee breaks, lunches, and dinners on both days",
        "Access to exhibition during the conference",
        "Special Discount on selected flights and hotels",
      ],
      quantity: 0,
    },
    {
      id: 2,
      name: "UAEFMA Member",
      price: 950,
      description: [
        "Full access to all the panel discussions and key note speeches",
        "Full access to all coffee breaks, lunches, and dinners on both days",
        "Access to exhibition during the conference",
        "Special Discount on selected flights and hotels",
      ],
      quantity: 0,
    },
  ];

  // Handle the click event for the ticket
  const handleTicketClick = (ticketId: number) => {
    setActiveTicketId(ticketId);
  };

  // Handle opening the modal instead of adding to the cart
  const handleAddToCart = (ticket: any) => {
    addToCart(ticket);
    setIsOrdersModalOpen(true); // Open the modal
    setActiveTicketId(ticket.id); // Set active ticket
  };

  return (
    <section id="tickets" className="tickets pt-120 pb-120" style={{ margin: 20 }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000} // 3 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          iconMapping={{
            success: <FaCheckCircle fontSize="inherit" />,
          }}
          sx={{
            backgroundColor:
              snackbar.severity === "success"
                ? "#EBECFD"
                : snackbar.severity === "error"
                ? "red"
                : "blue",
            color: "#0E131D",
            borderRadius: 2,
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 mb-130 text-center">
            <h1 className="title">Conference Tickets</h1>
            <p className="title-lead mt-10">
              Secure your spot now for an unmissable opportunity to gain
              valuable insights and network with industry leaders
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row tickets">
          <div className="col-sm-12">
            <div className="row">
              {tickets.map((ticket) => (
                <div className="col-sm-6" key={ticket.id}>
                  <ul className="block-tickets">
                    <li>
                      <ul
                        className={`block-ticket ${
                          ticket.id === activeTicketId ? "active" : ""
                        }`}
                        onClick={() => handleTicketClick(ticket.id)}
                      >
                        <li className="block-price">
                          <span className="price">
                            <span className="cur">$</span>
                            {ticket.price}
                          </span>
                          <span className="block-type">{ticket.name}</span>
                        </li>
                        {ticket.description.map((desc, idx) => (
                          <li
                            key={idx}
                            style={{
                              listStyleType: "none",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 50,
                            }}
                          >
                            <FaCheck style={{ marginRight: "8px" }} /> {desc}
                          </li>
                        ))}

                        <li>
                          <button
                            className="but mt-30"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddToCart(ticket); // Open modal on button click
                            }}
                          >
                            Select
                          </button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className="text-center pt-30">
          Please contact us for group registration for discounted rates.
        </p>
      </div>

      {/* Orders Modal */}
      <OrdersModal
        isOpen={isOrdersModalOpen}
        onClose={() => setIsOrdersModalOpen(false)}
        setSnackbar={setSnackbar}
        ticketId={activeTicketId}
      />
    </section>
  );
};

export default TicketsSection;
