import React, { useState, useEffect } from "react";
import { Table, Button, Input } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { doc, getDocs, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

type Coupon = {
  key: string;
  code: string;
  discount: number;
  active: boolean;
};

const ManageCoupon: React.FC = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [newCouponCode, setNewCouponCode] = useState<string>("");
  const [discountType, setDiscountType] = useState("percentage");
  const [newDiscount, setNewDiscount] = useState<number>(0);

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const fetchCoupons = async () => {
    const querySnapshot = await getDocs(collection(db, "coupons"));
    const couponsData: Coupon[] = querySnapshot.docs.map((doc) => ({
      key: doc.id,
      code: doc.data().code,
      discount: doc.data().discount,
      discountType: doc.data().discountType,
      active: doc.data().active,
    }));
    setCoupons(couponsData);
  };

  const handleAddCoupon = async () => {
    try {
      // Add a new coupon with the discount type
      await addDoc(collection(db, "coupons"), {
        code: newCouponCode,
        discount: newDiscount,
        discountType: discountType, // Store the discount type (percentage or exact)
        active: true, // New coupons are active by default
      });

      // Clear the input fields after adding the coupon
      setNewCouponCode("");
      setNewDiscount(0);
      setDiscountType("percentage"); // Reset the discount type to percentage by default
      fetchCoupons(); // Refresh the coupons table
    } catch (error) {
      console.error("Error adding coupon:", error);
    }
  };

  const toggleCouponStatus = async (key: string, currentStatus: boolean) => {
    try {
      const couponRef = doc(db, "coupons", key);
      await updateDoc(couponRef, { active: !currentStatus });
      fetchCoupons(); // Refresh the table data
    } catch (error) {
      console.error("Error updating coupon status:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const couponColumns = [
    { title: "Coupon Code", dataIndex: "code", key: "code" },
    { title: "Value", dataIndex: "discount", key: "discount" },
    { title: "Discount Type", dataIndex: "discountType", key: "discountType" }, // Add this column
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => (active ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: Coupon) => (
        <Button onClick={() => toggleCouponStatus(record.key, record.active)}>
          {record.active ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Coupons
      </h1>
      <br />
      <div
        style={{ marginBottom: "20px", display: "flex", flexDirection: "row" }}
      >
        <Input
          placeholder="Coupon Code"
          value={newCouponCode}
          onChange={(e) => setNewCouponCode(e.target.value)}
          style={{
            width: "400px",
            marginRight: "10px",
            color: "#000",
            border: "1px solid #B3B4F5",
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Discount Type Dropdown */}
          <select
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            style={{
              width: "200px",
              marginRight: "10px",
              color: "#000",
              border: "1px solid #B3B4F5",
              padding: "12px 10px",
              borderRadius: 50,
              marginBottom: 10,
            }}
          >
            <option value="percentage">Percentage</option>
            <option value="exact">Exact Value</option>
          </select>
          {/* Discount Input Field */}
          <Input
            placeholder={
              discountType === "percentage" ? "Discount (%)" : "Discount Amount"
            }
            value={newDiscount}
            onChange={(e) => setNewDiscount(Number(e.target.value))}
            style={{
              width: "200px",
              marginRight: "10px",
              color: "#000",
              border: "1px solid #B3B4F5",
            }}
          />
        </div>
        <button className="btn btn-register mb-5" onClick={handleAddCoupon}>
          Add Coupon
        </button>
      </div>
      <Table
        columns={couponColumns}
        dataSource={coupons}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageCoupon;
