import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { FaAngleLeft, FaPeopleArrows, FaPeopleCarry, FaPersonBooth, FaTable, FaTags, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ManageCoupon from "./ManageCoupons";
import ManageRegistrations from "./ManageRegistrations";
import ManageSpeakers from "./ManageSpeakers";

const { Sider, Content } = Layout;

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<string>("orders");

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear authentication state (e.g., remove the token)
    localStorage.removeItem("isAuthenticated");

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar */}
      <Sider width={250}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["orders"]}
          style={{
            height: "100%",
            borderRight: 0,
            padding: 20,
            background: "#EFF3F4",
          }}
        >
          <Menu.Item
            key="orders"
            icon={<FaTable />}
            onClick={() => setCurrentView("orders")}
            style={{
              color: currentView === "orders" ? "#0900DC" : "#0E131D",
              background: currentView === "orders" ? "#EBECFD" : "transparent",
              border: currentView === "orders" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Manage Registrations
          </Menu.Item>
          <Menu.Item
            key="coupons"
            icon={<FaTags />}
            onClick={() => setCurrentView("coupons")}
            style={{
              color: currentView === "coupons" ? "#0900DC" : "#0E131D",
              background: currentView === "coupons" ? "#EBECFD" : "transparent",
              border: currentView === "coupons" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Manage Coupons
          </Menu.Item>
          <Menu.Item
            key="speakers"
            icon={<FaUsers />}
            onClick={() => setCurrentView("speakers")}
            style={{
              color: currentView === "coupons" ? "#0900DC" : "#0E131D",
              background: currentView === "coupons" ? "#EBECFD" : "transparent",
              border: currentView === "coupons" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Manage Speakers
          </Menu.Item>
          <Menu.Item
            key="logout"
            icon={<FaAngleLeft />}
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              width: "100%",
              background: "transparent",
              color: "#0900DC",
              borderTop: "1px solid #ddd",
              paddingLeft: 75,
            }}
            onClick={handleLogout}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>

      {/* Main Content */}
      <Layout>
        <Content style={{ padding: "20px", background: "#fff" }}>
          {currentView === "orders" && <ManageRegistrations />}
          {currentView === "coupons" && <ManageCoupon />}
          {currentView === "speakers" && <ManageSpeakers />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
