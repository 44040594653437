import "./base.css";
import "./main.css";
import "./hero.css";

import Navigation from "./Navigation";
import { useState, useEffect } from "react";

const HeroSection = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="hero" className="hero overlay">
      {/* Main slider */}
      <div className="main-slider slider">
        <ul className="slides">
          <li>
            <div className="hero-img">
              &nbsp;
              {/* We no longer need the <img /> tag */}
            </div>
          </li>
        </ul>
      </div>
      {/* End main slider */}

      <Navigation />

      {/* Inner hero */}
      <div className="inner-hero fade-out">
        {/* Container */}
        <div className="container hero-content">
          {/* Row */}
          <div className="row">
            <div className={`col-sm-12 text-center pb-40 ${isSmallScreen ? 'pt-150' : ''}`}>
              <div
                className="col-sm-6 text-center"
                style={{ borderRight: !isSmallScreen ? "1px solid white": "", paddingRight: !isSmallScreen ? 70 :  10 }}
              >
                <img
                  src="https://res.cloudinary.com/astcdubai/image/upload/v1736164746/45th_Ica_white_logo_xoggsq.png"
                  alt={"logo"}
                  width={isSmallScreen ? 300 : "auto"}
                />
              </div>
              <div className="col-sm-6 text-left pt-15">
                <h1 className="title" style={{ fontSize: isSmallScreen ? 24 : 46, paddingLeft: 50 }}>
                  Connecting Minds, Shaping Financial Futures
                </h1>
              </div>
            </div>
            <div className="col-sm-12 text-center">
              <a href="#tickets" className="but scroll">
                Register Now
              </a>
            </div>
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
      {/* End inner hero */}
    </section>
  );
};

export default HeroSection;
