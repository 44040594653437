import React, { useState, useEffect } from "react";
import { Table, Button, Input, Upload, message } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDocs, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid'; // To generate unique image IDs

type Speaker = {
  key: string;
  name: string;
  role: string;
  company: string;
  shortBio: string;
  fullBio: string;
  imageUrl: string;
  timestamp: any;
};

const ManageSpeakers: React.FC = () => {
  const navigate = useNavigate();
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [image, setImageUrl] = useState<Speaker[]>([]);
  const [newSpeaker, setNewSpeaker] = useState<Speaker>({
    key: "",
    name: "",
    role: "",
    company: "",
    shortBio: "",
    fullBio: "",
    imageUrl: "",
    timestamp: new Date(),
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const storage = getStorage();

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const fetchSpeakers = async () => {
    const querySnapshot = await getDocs(collection(db, "speakers"));
    const speakersData: Speaker[] = querySnapshot.docs.map((doc) => ({
      key: doc.id,
      name: doc.data().name,
      role: doc.data().role,
      company: doc.data().company,
      shortBio: doc.data().shortBio,
      fullBio: doc.data().fullBio,
      imageUrl: doc.data().imageUrl,
      timestamp: doc.data().timestamp.toDate(),
    }));
    setSpeakers(speakersData);
  };

  // const handleImageUpload = async () => {
  //   if (imageFile) {
  //     const imageRef = ref(storage, `speakers/${uuidv4()}`);
  //     await uploadBytes(imageRef, imageFile);
  //     const imageUrl = await getDownloadURL(imageRef);
  //     setNewSpeaker((prev) => ({ ...prev, imageUrl }));
  //     message.success("Image uploaded successfully!");
  //   }
  // };

  const handleAddSpeaker = async () => {
    if (!newSpeaker.name || !newSpeaker.role || !newSpeaker.company) {
      message.error("Please fill out all the fields!");
      return;
    }
    try {
      await addDoc(collection(db, "speakers"), {
        ...newSpeaker,
      });

      setNewSpeaker({
        key: "",
        name: "",
        role: "",
        company: "",
        shortBio: "",
        fullBio: "",
        imageUrl: "",
        timestamp: new Date(),
      });
      setImageFile(null); // Reset image file
      fetchSpeakers(); // Refresh the speakers list
    } catch (error) {
      console.error("Error adding speaker:", error);
    }
  };

  useEffect(() => {
    fetchSpeakers();
  }, []);

  const speakerColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Short Bio", dataIndex: "shortBio", key: "shortBio" },
    { title: "Full Bio", dataIndex: "fullBio", key: "fullBio" },
    {
      title: "Image",
      key: "imageUrl",
      render: (imageUrl: string) => <img src={imageUrl} alt="Speaker" style={{ width: 100, height: 100 }} />,
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Manage Speakers
      </h1>
      <br />
      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Name"
          value={newSpeaker.name}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, name: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Role"
          value={newSpeaker.role}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, role: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Company"
          value={newSpeaker.company}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, company: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Image Url (Cloudinary)"
          value={newSpeaker.imageUrl}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, imageUrl: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input.TextArea
          placeholder="Short Bio"
          value={newSpeaker.shortBio}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, shortBio: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input.TextArea
          placeholder="Full Bio"
          value={newSpeaker.fullBio}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, fullBio: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        {/* <Upload
          beforeUpload={(file) => {
            setImageFile(file);
            return false; // Prevent automatic upload
          }}
          showUploadList={false}
          accept="image/*"
        >
          <Button>Upload Image</Button>
        </Upload>
        <Button onClick={handleImageUpload} style={{ marginTop: "10px" }}>Upload Image to Firebase</Button> */}
        <Button className="btn btn-register mb-5" onClick={handleAddSpeaker} style={{ marginTop: "10px" }}>
          Add Speaker
        </Button>
      </div>
      <Table
        columns={speakerColumns}
        dataSource={speakers}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageSpeakers;
