import "./partners.css";

const PartnersSection = () => {
  // Sponsors data with multiple logos for each type
  const partners: any = {
    association: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735883294/dubai_economy_white_vwn1ry.png",
        alt: "dubai",
      },
    ],
    flying: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735883694/emirates_white_kyy4oo.png",
        alt: "emirates",
      },
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735883693/fly_dubai_white_e56a89.png",
        alt: "fly_dubai",
      },
    ],
    training: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735882651/white-logo-astc_wolvoz.png",
        alt: "astc",
      },
      // {
      //   logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735882651/white-logo-astc_wolvoz.png",
      //   alt: "astc",
      // },
    ],
  };

  return (
    <section
      id="partners"
      className="pt-120 pb-120"
    >
      <div className="container">
        <div className="row text-center">
          {/* Association Partners Row */}
          <div className="col-md-12 text-center">
            <h1 className="title">Association Partners</h1>
            <div className="sponsor-row pt-20">
              {partners.association.map((sponsor: any, index: any) => (
                <div
                  key={index}
                  className="col-md-2 col-sm-2 sponsor association"
                >
                  <div className="block-sponsor">
                    <img src={sponsor.logo} alt={sponsor.alt} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row text-center pt-80">
          {/* Flying and Training Partners Row */}
          <div className="col-md-6 text-center">
            <h1 className="title">Flying Partners</h1>
            <div className="sponsor-row pt-20">
              {partners.flying.map((sponsor: any, index: any) => (
                <div key={index} className="col-md-3 col-sm-3 sponsor flying">
                  <div className="block-sponsor">
                    <img src={sponsor.logo} alt={sponsor.alt} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-6 text-center">
            <h1 className="title">Training Partners</h1>
            <div className="sponsor-row pt-20">
              {partners.training.map((sponsor: any, index: any) => (
                <div key={index} className="col-md-3 col-sm-3 sponsor training">
                  <div className="block-sponsor">
                    <img src={sponsor.logo} alt={sponsor.alt} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
