import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "./context/CartContext";
import { FaTrashAlt } from "react-icons/fa";
import { addDoc, collection, db } from "../firebaseConfig";
import "./orders.css";
import { getDocs } from "firebase/firestore";

interface OrdersModalProps {
  isOpen: boolean;
  ticketId: number | null;
  onClose: () => void;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }) => void;
}

const OrdersModal: React.FC<OrdersModalProps> = ({
  isOpen,
  onClose,
  setSnackbar,
}) => {
  const { cartItems, updateQuantity, removeCartItem, clearCart } =
    useContext(CartContext);
  const [coupon, setCoupon] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);
  const [couponAlert, setCouponAlert] = useState<string>("");
  const [coupons, setCoupons] = useState<
    { code: string; discount: number; discountType: string; active: boolean }[]
  >([]); // Store discount type as well

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [position, setPosition] = useState<string>("");

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponCollectionRef = collection(db, "coupons");
        const couponSnapshot = await getDocs(couponCollectionRef);
        const couponList = couponSnapshot.docs.map((doc) => ({
          code: doc.data().code,
          discount: doc.data().discount,
          discountType: doc.data().discountType, // Ensure we get the discount type
          active: doc.data().active,
        }));
        setCoupons(couponList);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);

  useEffect(() => {
    if (coupon === "") {
      setDiscount(0); // Revert to original total if coupon is cleared
      setCouponAlert(""); // Clear coupon alert when coupon is empty
    }
  }, [coupon]);

  const handleOrder = async () => {
    if (cartItems.length === 0) {
      setSnackbar({
        open: true,
        message: "You have not selected any tickets",
        severity: "error",
      });
      return;
    }

    const totalDelegateTickets = cartItems
      .filter((item) => item.name === "Delegate")
      .reduce((total, item) => total + item.quantity, 0);
    const totalUaefmaTickets = cartItems
      .filter((item) => item.name === "UAEFMA Member")
      .reduce((total, item) => total + item.quantity, 0);
    const orderData = {
      totalDelegateTickets,
      totalUaefmaTickets,
      coupon,
      name,
      email,
      organization,
      totalAmount: totalWithDiscount,
      timestamp: new Date(),
      position,
    };

    try {
      await addDoc(collection(db, "orders"), orderData);

      setSnackbar({
        open: true,
        message: "Registration has been successful!",
        severity: "success",
      });
      setTimeout(() => setSnackbar({ open: false, message: "", severity: "success" }), 3000); // Close after 3 seconds

      clearCart();
      setCoupon("");
      setCouponAlert("");
      setName("");
      setEmail("");
      setOrganization("");
      setPosition("");
      onClose(); // Close the modal after order is placed
    } catch (error) {
      setSnackbar({
        open: true,
        message: "There was an issue placing your order.",
        severity: "error",
      });
      console.error("Error adding document: ", error);
    }
  };

  const handleIncrease = (id: number) => {
    updateQuantity(id, "increment");
  };

  const handleDecrease = (id: number) => {
    updateQuantity(id, "decrement");
  };

  const handleDelete = (id: number) => {
    removeCartItem(id);

    if (cartItems.length > 0)
      setCoupon("");
      setCouponAlert("");
      setDiscount(0);
  };

  const applyCoupon = () => {
    const couponFound = coupons.find((c) => c.code === coupon && c.active);
    if (couponFound) {
      // Check the coupon type and apply accordingly
      if (couponFound.discountType === "percentage") {
        setDiscount(couponFound.discount);
        setCouponAlert(`Coupon applied: ${couponFound.discount}% off!`);
      } else if (couponFound.discountType === "exact") {
        // For exact value discounts, apply the exact amount
        setDiscount(couponFound.discount);
        setCouponAlert(`Coupon applied: $${couponFound.discount} off!`);
      }
    } else {
      setDiscount(0);
      setCouponAlert("Invalid coupon code");
    }
  };

  if (!isOpen) return null;

  const totalAmount = cartItems
    .reduce((total: any, item: any) => total + item.quantity * item.price, 0)
    .toFixed(2);

  const totalWithDiscount = couponAlert.includes("off")
    ? (
        parseFloat(totalAmount) -
        (couponAlert.includes("%")
          ? (parseFloat(totalAmount) * discount) / 100
          : discount)
      ).toFixed(2)
    : totalAmount;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h1 className="title">Tickets</h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="modal-body">
          <div className="cart-items-column">
            <h3 className="pb-10">Summary</h3>
            {cartItems.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              <ul className="cart-list-group">
                {cartItems.map((item: any) => (
                  <li key={item.id} className="cart-list-group-item">
                    <div
                      className="d-flex align-items-center"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ minWidth: 200 }}>{item.name}</span>
                      <div className="quantity-controls">
                        <button
                          className="btn btn-xs btn-secondary"
                          onClick={() => handleDecrease(item.id)}
                          disabled={item.quantity === 1}
                        >
                          -
                        </button>
                        <span
                          className="mx-3"
                          style={{ marginRight: 8, marginLeft: 8 }}
                        >
                          {item.quantity}
                        </span>
                        <button
                          className="btn btn-xs btn-secondary"
                          onClick={() => handleIncrease(item.id)}
                        >
                          +
                        </button>
                      </div>
                      <span>${(item.quantity * item.price).toFixed(2)}</span>

                      <div>
                        <FaTrashAlt
                          size={12}
                          onClick={() => handleDelete(item.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {/* Coupon Field */}
            <div className="coupon-section pt-10">
              <input
                type="text"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Enter coupon code"
                style={{ width: 300, marginRight: 10 }}
              />
              <button className="btn-register" onClick={applyCoupon}>
                Apply Coupon
              </button>

              <span className="pt-0">{couponAlert ?? ""}</span>
            </div>

            <div
              className="coupon-section"
              style={{ position: "absolute", bottom: 0 }}
            >
              <p className="small">
                Please contact us for group registration for discounted rates.
              </p>
            </div>
          </div>

          <div
            className="personal-info-column"
            style={{ paddingLeft: 20, borderLeft: "1px solid #fff" }}
          >
            <h3>Contact Person</h3>
            <p className="small pb-10">
              We will send payment instructions via email.
            </p>

            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
            <input
              type="text"
              placeholder="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
        </div>

        <div
          className="pt-20"
          style={{
            justifyContent: "space-between",
            display: "flex",
            textAlign: "center",
            borderTop: "1px solid #fff ",
          }}
        >
          {cartItems.length > 0 && (
            <div className="mt-4">
              <h4>Total: ${totalWithDiscount}</h4>
            </div>
          )}
          <button
            className={`btn-register ${
              cartItems.length === 0 || couponAlert.includes("Invalid")
                ? "disabled"
                : ""
            }`}
            onClick={handleOrder}
            disabled={cartItems.length === 0 || couponAlert.includes("Invalid")}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdersModal;
