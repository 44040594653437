import React from 'react';
import './whatsapp.css'; // Create a separate CSS file for styling
import { FaWhatsapp } from 'react-icons/fa';

const WhatsApp = () => {
  return (
    <div className='whatsapp-chat'>
      <a className="whatsapp-button" href="https://wa.me/+971552589011" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp size={30}/>
      </a>
    </div>
  );
};

export default WhatsApp;
