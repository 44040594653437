import React, { useEffect, useState } from "react";
import { Countdown, CountdownMobile } from "./Countdown";

const CounterSection = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="counter" className="countdown overlay parallax">
      {isSmallScreen ? (
        <div className="container">
          <div className="counter-img"> &nbsp;</div>
          <div className="col-sm-8 col-sm-offset-2 text-center front-p">
            {isSmallScreen ? (
              <h1 className="title">Time Left Until the <br /> Conference Starts</h1>
            ) : (
              <h1 className="title">Time Left Until the Conference Starts</h1>
            )}
            <p className="title-lead-0 pt-8">13th - 15th November 2024</p>
          </div>
          <span className="front-p">
            <CountdownMobile />
          </span>
        </div>
      ) : (
        <div className="container">
          <div className="counter-img"> &nbsp;</div>
          <div className="counter-row">
            <div className="col-sm-8 col-sm-offset-2 text-center front-p">
              <h1 className="title">Time Left Until the Conference Starts</h1>
              <p className="title-lead-0">13th - 15th November 2024</p>
            </div>
          </div>
          <div className="col-sm-12 text-center front-p">
            <Countdown />
          </div>
        </div>
      )}
    </section>
  );
};

export default CounterSection;
