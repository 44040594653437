import React from "react";

const SponsorsSection = () => {
  // Sponsors data with multiple logos for each type
  const sponsors: any = {
    lead: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Lead Sponsor 1",
      },
    ],
    diamond: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Diamond Sponsor 1",
      },
    ],
    platinum: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Platinum Sponsor 1",
      },
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Platinum Sponsor 2",
      },
    ],
    gold: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Gold Sponsor 1",
      },
    ],
    silver: [
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Silver Sponsor 1",
      },
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Silver Sponsor 2",
      },
      {
        logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
        alt: "Silver Sponsor 3",
      },
    ],
  };

  return (
    <section id="sponsors" className="sponser pt-100 pb-100">
      <div className="container">
        <div className="row text-center">
          <h1 className="title">
            Our Sponsors
          </h1>
          {/* {Object.keys(sponsors).map((type) => (
            <div key={type} className={`col-md-12 text-center`}>
              <br />
              <h1 className="title">
                {type.charAt(0).toUpperCase() + type.slice(1)} Sponsors
              </h1>

              <br />

              <div className="sponsor-row">
                {sponsors[type].map((sponsor: any, index: any) => (
                  <div
                    key={index}
                    className={`col-md-2 col-sm-2 sponsor ${type}`}
                  >
                    <div className="block-sponsor">
                      <img src={sponsor.logo} alt={sponsor.alt} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </section>
  );
};

export default SponsorsSection;
