import React, { useState } from "react";

const ScheduleSection = () => {
  const [activeDay, setActiveDay] = useState("Day 1");

  const dayDateMap: any = {
    "Day 1": "13 November 2025",
    "Day 2": "14 November 2025",
    "Day 3": "15 November 2025",
    "Day 4": "16 November 2025",
  };

  const schedule: Record<
    string,
    Array<{ time: string; topic: string; speaker?: string; about?: string }>
  > = {
    "Day 1": [
      {
        time: "09:00 - 16:00",
        topic: "Delegate Registration and Badge Distribution",
      },
      {
        time: "18:00 - 21:00",
        topic: "Cocktail Reception",
      },
    ],
    "Day 2": [
      {
        time: "08:30 - 09:00",
        topic:
          "Delegate Registration and Badge Distribution - Networking Exhibition",
      },
      {
        time: "09:30 - 10:00",
        topic: "Official Inauguration and Opening Speech",
      },
      {
        time: "10:00 - 10:50",
        topic: "Panel 1: TBA",
        speaker: "TBA",
      },
      { time: "10:50 - 11:20", topic: "Coffee Break" },
      {
        time: "11:20 - 12:10",
        topic: "Panel 2: TBA",
        speaker: "TBA",
      },
      {
        time: "12:10 - 13:00",
        topic: "Panel 3: TBA",
        speaker: "TBA",
      },
      {
        time: "13:00 - 13:20",
        topic: "TBA",
        speaker: "TBA",
      },
      {
        time: "13:20 - 14:20",
        topic: "Lunch",
      },
      {
        time: "14:20 - 15:00",
        topic: "Panel 4: TBA",
        speaker: "TBA",
      },
      {
        time: "15:00 - 15:30",
        topic: "TBA",
        speaker: "TBA",
      },
      {
        time: "19:30 - 23:00",
        topic: "Dinner",
      },
    ],
    "Day 3": [
      {
        time: "09:30 - 10:00",
        topic: "Networking Exhibition",
      },
      {
        time: "10:00 - 10:50",
        topic: "Panel 5: TBA",
      },
      { time: "10:50 - 11:20", topic: "TBA" },
      {
        time: "11:20 - 11:50",
        topic: "Coffee Break and Networking",
      },
      {
        time: "11:50 - 12:40",
        topic: "Panel 6: TBA",
        speaker: "TBA",
      },
      {
        time: "12:40 - 14:00",
        topic: "Lunch",
      },
      {
        time: "15:00",
        topic: "End",
      },
    ],
    "Day 4": [
      {
        time: "10:00 - onwards",
        topic: "Excursion Day",
      },
    ],
  };

  return (
    <section id="agenda" className="schedule pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
            <h1 className="title">Agenda</h1>
            <p className="title-lead mt-10">
              Discover a dynamic lineup of sessions designed to inspire,
              educate, and connect.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <ul className="block-tabs">
              {Object.keys(schedule).map((day) => (
                <li
                  key={day}
                  className={`tab-item ${activeDay === day ? "active" : ""}`}
                  onClick={() => setActiveDay(day)}
                >
                  <div className="block-info-1 et-line-calendar">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="25px"
                      height="20px"
                      viewBox="0 0 42 32"
                      enable-background="new 0 0 42 32"
                      xmlSpace="preserve"
                    >
                      <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="5.1983"
                        y1="28.1187"
                        x2="43.4067"
                        y2="11.702"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_1_)"
                        d="M38,30.5v-19c0-0.276-0.224-0.5-0.5-0.5S37,11.224,37,11.5v19c0,0.276-0.224,0.5-0.5,0.5h-31
                              C5.224,31,5,30.776,5,30.5v-19C5,11.224,4.776,11,4.5,11S4,11.224,4,11.5v19C4,31.327,4.673,32,5.5,32h31
                              C37.327,32,38,31.327,38,30.5z"
                      />
                      <linearGradient
                        id="SVGID_2_"
                        gradientUnits="userSpaceOnUse"
                        x1="4.7162"
                        y1="26.9965"
                        x2="42.9245"
                        y2="10.5799"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_2_)"
                        d="M8.5,23C8.224,23,8,23.224,8,23.5S8.224,24,8.5,24H10v3.5c0,0.276,0.224,0.5,0.5,0.5
                              s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24
                              h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h1.5c0.276,0,0.5-0.224,0.5-0.5S33.776,23,33.5,23H32v-5h1.5
                              c0.276,0,0.5-0.224,0.5-0.5S33.776,17,33.5,17H32v-4.5c0-0.276-0.224-0.5-0.5-0.5S31,12.224,31,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S24,12.224,24,12.5V17h-6v-4.5c0-0.276-0.224-0.5-0.5-0.5S17,12.224,17,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S10,12.224,10,12.5V17H8.5C8.224,17,8,17.224,8,17.5S8.224,18,8.5,18H10v5H8.5z M31,18v5h-6v-5H31z
                              M24,18v5h-6v-5H24z M11,18h6v5h-6V18z"
                      />
                      <linearGradient
                        id="SVGID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="-0.4811"
                        y1="14.9003"
                        x2="37.7272"
                        y2="-1.5164"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_3_)"
                        d="M32.5,3h4.25C36.837,3,37,3,37,3.5V8H5V3.5C5,3.224,5.224,3,5.5,3h4C9.776,3,10,2.776,10,2.5
                              S9.776,2,9.5,2h-4C4.673,2,4,2.673,4,3.5v5C4,8.776,4.224,9,4.5,9h33C37.776,9,38,8.776,38,8.5v-5C38,2.394,37.354,2,36.75,2H32.5
                              C32.224,2,32,2.224,32,2.5S32.224,3,32.5,3z"
                      />
                      <linearGradient
                        id="SVGID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1="-1.6311"
                        y1="12.2237"
                        x2="36.5772"
                        y2="-4.1929"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_4_)"
                        d="M26.5,3C26.776,3,27,2.776,27,2.5S26.776,2,26.5,2h-11C15.224,2,15,2.224,15,2.5S15.224,3,15.5,3
                              H26.5z"
                      />
                      <linearGradient
                        id="SVGID_5_"
                        gradientUnits="userSpaceOnUse"
                        x1="-2.9557"
                        y1="9.1407"
                        x2="35.2526"
                        y2="-7.2759"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_5_)"
                        d="M13,4.5v-4C13,0.224,12.776,0,12.5,0S12,0.224,12,0.5v4C12,4.776,12.224,5,12.5,5S13,4.776,13,4.5z"
                      />
                      <linearGradient
                        id="SVGID_6_"
                        gradientUnits="userSpaceOnUse"
                        x1="-0.3065"
                        y1="15.3067"
                        x2="37.9019"
                        y2="-1.11"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_6_)"
                        d="M29.5,5C29.776,5,30,4.776,30,4.5v-4C30,0.224,29.776,0,29.5,0S29,0.224,29,0.5v4
                              C29,4.776,29.224,5,29.5,5z"
                      />
                    </svg>
                    <span style={{ marginLeft: 40 }}>
                      <strong>{day}</strong> <span>- {dayDateMap[day]}</span>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-sm-8">
            {/* Session list */}
            <ul className="block-tab">
              <li className="active">
                <div className="block-date">
                  <div className="block-info-1 et-line-calendar">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="25px"
                      height="20px"
                      viewBox="0 0 42 32"
                      enable-background="new 0 0 42 32"
                      xmlSpace="preserve"
                    >
                      <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="5.1983"
                        y1="28.1187"
                        x2="43.4067"
                        y2="11.702"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_1_)"
                        d="M38,30.5v-19c0-0.276-0.224-0.5-0.5-0.5S37,11.224,37,11.5v19c0,0.276-0.224,0.5-0.5,0.5h-31
                              C5.224,31,5,30.776,5,30.5v-19C5,11.224,4.776,11,4.5,11S4,11.224,4,11.5v19C4,31.327,4.673,32,5.5,32h31
                              C37.327,32,38,31.327,38,30.5z"
                      />
                      <linearGradient
                        id="SVGID_2_"
                        gradientUnits="userSpaceOnUse"
                        x1="4.7162"
                        y1="26.9965"
                        x2="42.9245"
                        y2="10.5799"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_2_)"
                        d="M8.5,23C8.224,23,8,23.224,8,23.5S8.224,24,8.5,24H10v3.5c0,0.276,0.224,0.5,0.5,0.5
                              s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24
                              h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h1.5c0.276,0,0.5-0.224,0.5-0.5S33.776,23,33.5,23H32v-5h1.5
                              c0.276,0,0.5-0.224,0.5-0.5S33.776,17,33.5,17H32v-4.5c0-0.276-0.224-0.5-0.5-0.5S31,12.224,31,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S24,12.224,24,12.5V17h-6v-4.5c0-0.276-0.224-0.5-0.5-0.5S17,12.224,17,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S10,12.224,10,12.5V17H8.5C8.224,17,8,17.224,8,17.5S8.224,18,8.5,18H10v5H8.5z M31,18v5h-6v-5H31z
                              M24,18v5h-6v-5H24z M11,18h6v5h-6V18z"
                      />
                      <linearGradient
                        id="SVGID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="-0.4811"
                        y1="14.9003"
                        x2="37.7272"
                        y2="-1.5164"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_3_)"
                        d="M32.5,3h4.25C36.837,3,37,3,37,3.5V8H5V3.5C5,3.224,5.224,3,5.5,3h4C9.776,3,10,2.776,10,2.5
                              S9.776,2,9.5,2h-4C4.673,2,4,2.673,4,3.5v5C4,8.776,4.224,9,4.5,9h33C37.776,9,38,8.776,38,8.5v-5C38,2.394,37.354,2,36.75,2H32.5
                              C32.224,2,32,2.224,32,2.5S32.224,3,32.5,3z"
                      />
                      <linearGradient
                        id="SVGID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1="-1.6311"
                        y1="12.2237"
                        x2="36.5772"
                        y2="-4.1929"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_4_)"
                        d="M26.5,3C26.776,3,27,2.776,27,2.5S26.776,2,26.5,2h-11C15.224,2,15,2.224,15,2.5S15.224,3,15.5,3
                              H26.5z"
                      />
                      <linearGradient
                        id="SVGID_5_"
                        gradientUnits="userSpaceOnUse"
                        x1="-2.9557"
                        y1="9.1407"
                        x2="35.2526"
                        y2="-7.2759"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_5_)"
                        d="M13,4.5v-4C13,0.224,12.776,0,12.5,0S12,0.224,12,0.5v4C12,4.776,12.224,5,12.5,5S13,4.776,13,4.5z"
                      />
                      <linearGradient
                        id="SVGID_6_"
                        gradientUnits="userSpaceOnUse"
                        x1="-0.3065"
                        y1="15.3067"
                        x2="37.9019"
                        y2="-1.11"
                      >
                        <stop offset="0" style={{ stopColor: "#e7a73b" }} />
                        <stop offset="1" style={{ stopColor: "#e7a73b" }} />
                      </linearGradient>
                      <path
                        fill="url(#SVGID_6_)"
                        d="M29.5,5C29.776,5,30,4.776,30,4.5v-4C30,0.224,29.776,0,29.5,0S29,0.224,29,0.5v4
                              C29,4.776,29.224,5,29.5,5z"
                      />
                    </svg>
                    <span style={{ marginLeft: 40 }}>
                      <strong>{activeDay}</strong>
                      <span> - {dayDateMap[activeDay]}</span>
                    </span>
                  </div>
                </div>
              </li>

              {schedule[activeDay]?.map((session, index) => (
                <li key={index} className="active">
                  <div
                    className="block-detail"
                    style={{ borderBottom: "1px solid #444444" }}
                  >
                    <span className="time">{session.time}</span>
                    <span className="topic">{session.topic}</span>

                    {session.about && (
                      <div className="block-text">
                        <p>{session.about}</p>
                        <span className="speaker">
                          <strong>Speaker</strong> :{" "}
                          <a href="#" className="gradient-text">
                            {session.speaker}
                          </a>{" "}
                        </span>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScheduleSection;
